<template>
  <div class="important-page-c display--flex fd--column feerecv-w">
    <el-tabs v-model="activeName" type="border-card" @tab-click="onTabsClick">
      <el-tab-pane label="代预报名催收" name="yubaoming">
        <!-- 搜索 -->
        <div class="search-form btn-2b5a95">
          <div class="l-w" style="width: 85%">
            <el-form
              label-position="right"
              :class="
                $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
              "
							@submit.native.prevent
							:model="searchForm"
            >
              <el-form-item label="潜在学生姓名:">
                <el-input 
									v-model.trim="searchForm.qianzainame" 
									@keyup.enter.native="searchYubaoming"
									clearable />
              </el-form-item>
              <el-form-item label="学生手机号:">
                <el-input 
									v-model.trim="searchForm.mobile"
									@keyup.enter.native="searchYubaoming"
									clearable />
              </el-form-item>
              <el-form-item label="跟进人:">
                <el-input 
									v-model.trim="searchForm.followusername" 
									@keyup.enter.native="searchYubaoming"
									clearable />
              </el-form-item>
              <el-form-item label="教学点:">
                <el-input 
									v-model.trim="searchForm.schoolorgname" 
									@keyup.enter.native="searchYubaoming"
									clearable />
              </el-form-item>
            </el-form>
          </div>
          <div class="r-w">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchYubaoming"
              :loading="!$store.state.global.isEndRequest"
              class="btn-2b5a95"
              >搜索</el-button
            >
						<el-button type="primary" @click="resetQuery">重置</el-button>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="Btn-w eaf4ff-btn">
          <div class="btn-l">
            <el-button type="primary" @click="onYubaomingCharge"
              >收款</el-button
            >
            <el-button type="primary" @click="onYubaomingExport"
              >导出</el-button
            >
            <el-button type="primary" @click="baoming_arrearslist"
              >刷新</el-button
            >
          </div>
        </div>
        <!-- 列表表格 -->
        <el-table
          :data="yubaomingRes.list"
          stripe
          border
          :header-cell-style="{ background: '#044d8c', color: '#fff'}"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            label="潜在学生姓名"
            prop="qianzainame"
            align="center"
          />
          <el-table-column label="学生手机号码" prop="mobile" align="center" >
						<template slot-scope="scope" v-if="scope.row.mobile !== null">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					</el-table-column>
					</el-table-column>
          <el-table-column label="报读年级" prop="year" align="center" />
          <el-table-column label="应收金额" prop="totalmoney" align="center" />
          <el-table-column label="实收金额" prop="recvmoney" align="center" />
          <el-table-column label="退费金额" prop="feebackmoney" align="center">
          </el-table-column>
          <el-table-column label="欠费金额" align="center">
            <template slot-scope="scope">
              {{   Math.abs(scope.row.totalmoney - scope.row.recvmoney).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
            label="跟进人姓名"
            prop="followusername"
            align="center"
          />
          <el-table-column label="教学点" prop="schoolorgname" align="center" />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          @current-change="
            (val, method) => handleCurrentChange(val, 'baoming_arrearslist')
          "
          :current-page="pageinfo.pageindex"
          :page-size="pageinfo.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          layout="total, prev, pager, next, jumper"
          :total="yubaomingRes.totalcount"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="德立学生学位费催收" name="xuewei">
        <!-- 搜索 -->
        <div class="search-form btn-2b5a95">
          <div class="l-w" style="width: 85%">
            <el-form
              label-position="right"
              :class="
                $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
              "
							:model="searchForm"
							@submit.native.prevent
            >
              <el-form-item label="潜在学生姓名:">
                <el-input 
									v-model.trim="searchForm.qianzainame" 
									@keyup.enter.native="searchXuewei"
									clearable />
              </el-form-item>
              <el-form-item label="学生手机号:">
                <el-input 
									v-model.trim="searchForm.mobile" 
									@keyup.enter.native="searchXuewei"
									clearable />
              </el-form-item>
              <el-form-item label="跟进人:">
                <el-input 
									v-model.trim="searchForm.followusername"
									@keyup.enter.native="searchXuewei" 
									clearable />
              </el-form-item>
              <el-form-item label="教学点:">
                <el-input 
									v-model.trim="searchForm.schoolorgname" 
									@keyup.enter.native="searchXuewei"
									clearable />
              </el-form-item>
            </el-form>
          </div>
          <div class="r-w">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchXuewei"
              :loading="!$store.state.global.isEndRequest"
              class="btn-2b5a95"
              >搜索</el-button
            >
						<el-button type="primary" @click="resetXuewei">重置</el-button>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="Btn-w eaf4ff-btn">
          <div class="btn-l">
            <el-button type="primary" @click="onXueweiCharge">收款</el-button>
            <el-button type="primary" @click="onXueweiExport">导出</el-button>
            <el-button type="primary" @click="student_arrearslist"
              >刷新</el-button
            >
          </div>
        </div>
        <!-- 列表表格 -->
        <el-table
          :data="xueweiRes.list"
          stripe
          border
          :header-cell-style="{ background: '#044d8c', color: '#fff'}"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="学生姓名" prop="qianzainame" align="center" />
          <el-table-column label="手机号" prop="mobile" align="center" >
						<template slot-scope="scope" v-if="scope.row.mobile !== null">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
						</template>
					</el-table-column>
          <el-table-column label="正式学生" align="center" prop="isokflag">
            <template slot-scope="scope">
              {{ _available[scope.row.isokflag] }}
            </template>
          </el-table-column>
          <el-table-column
            label="学习形式"
            prop="studytypeval"
            align="center"
          />
          <el-table-column
            label="预报名类型"
            prop="baomingtypeval"
            align="center"
          />
          <el-table-column
            label="应收金额"
            prop="shouldrecvmoney"
            align="center"
          />
          <el-table-column
            label="实收金额"
            prop="realrecvmoney"
            align="center"
          />
          <el-table-column label="退费金额" prop="feebackmoney" align="center">
          </el-table-column>
					<el-table-column label="优惠" prop="discountfee" align="center">
					</el-table-column>
          <el-table-column label="欠费金额" align="center">
            <template slot-scope="scope">
              {{
                Math.abs(
                  scope.row.shouldrecvmoney -
                    scope.row.realrecvmoney -
										scope.row.discountfee +
                    scope.row.feebackmoney
                ).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="跟进人姓名"
            prop="followusername"
            align="center"
          />
          <el-table-column label="教学点" prop="schoolorgname" align="center" />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          @current-change="
            (val, method) => handleCurrentChange(val, 'student_arrearslist')
          "
          :current-page="pageinfo.pageindex"
          :page-size="pageinfo.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          layout="total, prev, pager, next, jumper"
          :total="xueweiRes.totalcount"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="购买辅导教材费催收" name="fudao">
        <!-- 搜索 -->
        <div class="search-form btn-2b5a95">
          <div class="l-w" style="width: 85%">
            <el-form
              label-position="right"
              :class="
                $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
              "
							:model="searchForm"
							@submit.native.prevent
            >
              <el-form-item label="潜在学生姓名:">
                <el-input 
									v-model.trim="searchForm.studentname" 
									@keyup.enter.native="searchFudao"
									clearable />
              </el-form-item>
              <el-form-item label="学生手机号:">
                <el-input 
									v-model.trim="searchForm.mobile" 
									@keyup.enter.native="searchFudao"
									clearable />
              </el-form-item>
              <el-form-item label="跟进人:">
                <el-input 
									v-model.trim="searchForm.followusername" 
									@keyup.enter.native="searchFudao"
									clearable />
              </el-form-item>
              <el-form-item label="教学点:">
                <el-input 
									v-model.trim="searchForm.schoolorgname" 
									@keyup.enter.native="searchFudao"
									clearable />
              </el-form-item>
            </el-form>
          </div>
          <div class="r-w">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchFudao"
              :loading="!$store.state.global.isEndRequest"
              class="btn-2b5a95"
              >搜索</el-button
            >
						<el-button type="primary" @click="resetFudao">重置</el-button>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="Btn-w eaf4ff-btn">
          <div class="btn-l">
            <el-button type="primary" @click="onFudaoCharge">收款</el-button>
            <el-button type="primary" @click="onFudaoExport">导出</el-button>
            <el-button type="primary" @click="studentbook_arrearslist"
              >刷新</el-button
            >
          </div>
        </div>
        <!-- 列表表格 -->
        <el-table
          :data="fudaoRes.list"
          stripe
          border
          :header-cell-style="{ background: '#044d8c', color: '#fff'}"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="学生姓名" prop="studentname" align="center" />
          <el-table-column label="手机号" prop="mobile" align="center" >
						<template slot-scope="scope" v-if="scope.row.mobile !== null">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
						</template>
					</el-table-column>
          <el-table-column label="总数量" align="center" prop="totalquality">
          </el-table-column>
          <el-table-column label="购买时间" prop="buytime" align="center" >
            <template slot-scope="scope">
              {{
                scope.row.buytime | formatDate('YYYY-MM-DD HH:mm:ss')
              }}
            </template>
          </el-table-column>

          <el-table-column
            label="应收总金额"
            prop="totalmoney"
            align="center"
          />
          <el-table-column label="实收金额" prop="recvmoney" align="center" />
          <el-table-column
            label="优惠金额"
            prop="preferentialmoney"
            align="center"
          />
          <el-table-column label="退费金额" prop="feebackmoney" align="center">
          </el-table-column>
          <el-table-column label="欠费金额" align="center">
            <template slot-scope="scope">
              {{
                Math.abs(
                  scope.row.totalmoney -
                    scope.row.recvmoney -
                    scope.row.preferentialmoney +
                    scope.row.feebackmoney
                ).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="跟进人姓名"
            prop="followusername"
            align="center"
          />
          <el-table-column label="教学点" prop="schoolorgname" align="center" />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          @current-change="
            (val, method) => handleCurrentChange(val, 'studentbook_arrearslist')
          "
          :current-page="pageinfo.pageindex"
          :page-size="pageinfo.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          layout="total, prev, pager, next, jumper"
          :total="fudaoRes.totalcount"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="入学注册学费催收" name="ruxue">
        <!-- 搜索 -->
        <div class="search-form btn-2b5a95">
          <div class="l-w" style="width: 85%">
            <el-form
              label-position="right"
              :class="
                $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
              "
							:model="searchForm"
							@submit.native.prevent
            >
              <el-form-item label="潜在学生姓名:">
                <el-input 
									v-model.trim="searchForm.studentname"
									@keyup.enter.native="searchRuxue"
									clearable />
              </el-form-item>
              <el-form-item label="学生手机号:">
                <el-input 
									v-model.trim="searchForm.mobile" 
									@keyup.enter.native="searchRuxue"
									clearable />
              </el-form-item>
              <el-form-item label="跟进人:">
                <el-input 
									v-model.trim="searchForm.followusername"
									@keyup.enter.native="searchRuxue"
									clearable />
              </el-form-item>
              <el-form-item label="教学点:">
                <el-input 
									v-model.trim="searchForm.schoolorgname" 
									@keyup.enter.native="searchRuxue"
									clearable />
              </el-form-item>
            </el-form>
          </div>
          <div class="r-w">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchRuxue"
              :loading="!$store.state.global.isEndRequest"
              class="btn-2b5a95"
              >搜索</el-button
            >
						<el-button type="primary" @click="resetRuxue">重置</el-button>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="Btn-w eaf4ff-btn">
          <div class="btn-l">
            <el-button type="primary" @click="onRuxueCharge">收款</el-button>
            <el-button type="primary" @click="onRuxueExport">导出</el-button>
            <el-button
              type="primary"
              @click="studentregisterfeedetail_arrearslist"
              >刷新</el-button
            >
          </div>
        </div>
        <!-- 列表表格 -->
        <el-table
          :data="ruxueRes.list"
          stripe
          border
          :header-cell-style="{ background: '#044d8c', color: '#fff'}"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="学生姓名" prop="studentname" align="center" />
          <el-table-column label="手机号" prop="mobile" align="center" >
						<template slot-scope="scope" v-if="scope.row.mobile !== null">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
						</template>
					</el-table-column>
          <el-table-column label="学年" align="center" prop="iyear">
          </el-table-column>
          <el-table-column label="院校" prop="collegename" align="center" />
          <el-table-column label="应收金额" prop="money" align="center" />
          <el-table-column
            label="实收金额"
            prop="recvitemmoney"
            align="center"
          />
          <el-table-column
            label="减免金额"
            prop="extrasubmoney"
            align="center"
          />
          <el-table-column label="优惠金额" prop="discountfee" align="center">
          </el-table-column>
          <el-table-column label="预交学位费" prop="studentfee" align="center">
          </el-table-column>
          <el-table-column label="预交其他" prop="otherfee" align="center">
          </el-table-column>
          <el-table-column label="退费" prop="feebackmoney" align="center">
          </el-table-column>
          <el-table-column label="欠费金额" align="center">
            <template slot-scope="scope">
              {{
                Math.abs(
                  scope.row.money -
                    scope.row.recvitemmoney -
                    scope.row.extrasubmoney -
                    scope.row.discountfee -
                    scope.row.studentfee -
                    scope.row.otherfee +
                    scope.row.feebackmoney
                ).toFixed(2)
              }}
            </template>
          </el-table-column>

          <el-table-column
            label="跟进人姓名"
            prop="followusername"
            align="center"
          />
          <el-table-column label="教学点" prop="schoolorgname" align="center" />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          @current-change="
            (val, method) =>
              handleCurrentChange(val, 'studentregisterfeedetail_arrearslist')
          "
          :current-page="pageinfo.pageindex"
          :page-size="pageinfo.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          layout="total, prev, pager, next, jumper"
          :total="ruxueRes.totalcount"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="报读辅导班学费催收" name="baodu"
        >
        <!-- 搜索 -->
        <div class="search-form btn-2b5a95">
          <div class="l-w" style="width: 85%">
            <el-form
              label-position="right"
              :class="
                $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
              "
							:model="searchForm"
							@submit.native.prevent
            >
              <el-form-item label="潜在学生姓名:">
                <el-input 
									v-model.trim="searchForm.studentname" 
									@keyup.enter.native="searchBaodu"
									clearable />
              </el-form-item>
              <el-form-item label="学生手机号:">
                <el-input 
									v-model.trim="searchForm.mobile"
									 @keyup.enter.native="searchBaodu"
									clearable />
              </el-form-item>
              <el-form-item label="跟进人:">
                <el-input 
									v-model.trim="searchForm.followusername" 
									@keyup.enter.native="searchBaodu"
									clearable />
              </el-form-item>
              <el-form-item label="教学点:">
                <el-input 
									v-model.trim="searchForm.schoolorgname" 
									@keyup.enter.native="searchBaodu"
									clearable />
              </el-form-item>
            </el-form>
          </div>
          <div class="r-w">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="searchBaodu"
              :loading="!$store.state.global.isEndRequest"
              class="btn-2b5a95"
              >搜索</el-button
            >
						<el-button type="primary" @click="resetBaodu">重置</el-button>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="Btn-w eaf4ff-btn">
          <div class="btn-l">
            <el-button type="primary" @click="onBaoduCharge">收款</el-button>
            <el-button type="primary" @click="onBaoduExport">导出</el-button>
            <el-button type="primary" @click="studentfudao_arrearslist"
              >刷新</el-button
            >
          </div>
        </div>
        <!-- 列表表格 -->
        <el-table
          :data="baoduRes.list"
					stripe
					border
					:header-cell-style="{ background: '#044d8c', color: '#fff'}"
          ref="multipleTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="学生姓名" prop="studentname" align="center" />
          <el-table-column label="手机号" prop="mobile" align="center" >
						<template slot-scope="scope" v-if="scope.row.mobile !== null">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
						</template>
					</el-table-column>
          <el-table-column label="报读时间" align="regdate" prop="iyear">
             <template slot-scope="scope">
              {{
                scope.row.regdate | formatDate('YYYY-MM-DD HH:mm:ss')
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="辅导班班别"
            prop="fudaoclasstypename"
            align="center"
          />
          <el-table-column
            label="应收总金额"
            prop="totalmoney"
            align="center"
          />
          <el-table-column label="实收金额" prop="recvmoney" align="center" />
          <el-table-column
            label="减免金额"
            prop="extrasubmoney"
            align="center"
          />
          <el-table-column
            label="优惠金额"
            prop="preferentialmoney"
            align="center"
          >
          </el-table-column>
          <el-table-column label="退费金额" prop="feebackmoney" align="center">
          </el-table-column>
          <el-table-column label="欠费金额" align="center">
            <template slot-scope="scope">
              {{
                Math.abs(
                  scope.row.totalmoney -
                    scope.row.recvmoney -
                    scope.row.extrasubmoney -
                    scope.row.preferentialmoney +
                    scope.row.feebackmoney
                ).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="跟进人姓名"
            prop="followusername"
            align="center"
          />
          <el-table-column label="教学点" prop="schoolorgname" align="center" />
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          @current-change="
            (val, method) =>
              handleCurrentChange(val, 'studentfudao_arrearslist')
          "
          :current-page="pageinfo.pageindex"
          :page-size="pageinfo.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          layout="total, prev, pager, next, jumper"
          :total="baoduRes.totalcount"
        ></el-pagination>
      </el-tab-pane>
			<el-tab-pane label="技能培训催收" name="skill">
				<!-- 搜索 -->
				<div class="search-form btn-2b5a95">
					<div class="l-w" style="width: 85%;">
						<el-form
							label-position="right"
							:class="
								$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
							"
							:model="searchForm"
							@submit.native.prevent
						>
							<el-form-item label="学生姓名:">
								<el-input 
									v-model="searchForm.studentname" 
									@keyup.enter.native="searchTeachfudao"
									clearable
									/>
							</el-form-item>
							<el-form-item label="手机号:">
								<el-input 
									v-model="searchForm.mobile" 
									@keyup.enter.native="searchTeachfudao"
									clearable
									/>
							</el-form-item>
							<el-form-item label="培训类型:">
								<el-input 
									v-model="searchForm.fudaotypeval" 
									@keyup.enter.native="searchTeachfudao"
									clearable
									/>
							</el-form-item>
							<el-form-item label="跟进人:">
								<el-input 
									v-model="searchForm.followusername" 
									@keyup.enter.native="searchTeachfudao"
									clearable
									/>
							</el-form-item>
							<el-form-item label="教学点:">
								<el-input 
									v-model="searchForm.schoolorgname" 
									@keyup.enter.native="searchTeachfudao"
									clearable
									/>
							</el-form-item>
						</el-form>
					</div>
					<div class="r-w">
						<el-button
						  type="primary"
						  icon="el-icon-search"
						  @click="searchTeachfudao"
						  :loading="!$store.state.global.isEndRequest"
						  class="btn-2b5a95"
						  >搜索</el-button
						>
						<el-button type="primary" @click="resetTeachfudao">重置</el-button>
					</div>
				</div>
				<div class="Btn-w eaf4ff-btn">
					<div class="btn-l">
						<el-button type="primary" @click="onTeachCharge">收款</el-button>
						<el-button type="primary" @click="onTeachExport">导出</el-button>
						<el-button type="primary" @click="teachfudao_arrearslist">刷新</el-button>
					</div>
				</div>
				<!-- 操作 -->
				<el-table
					:data="skillRes.list"
					stripe
					border
					:header-cell-style="{ background: '#044d8c', color: '#fff'}"
					ref="multipleTable"
					style="width: 100%;"
					@selection-change="handleSelectionChange"
				>
					<el-table-column type="selection" width="55"/>
					<el-table-column label="学生姓名" prop="studentname" align="center"></el-table-column>
					<el-table-column label="手机号" prop="mobile" align="center">
						<template slot-scope="scope" v-if="scope.row.mobile !== null">
							{{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
						</template>
					</el-table-column>
					<el-table-column label="班别名称" prop="fudaoclasstypename" align="center"></el-table-column>
					<el-table-column label="培训类型" prop="fudaotypeval" align="center"></el-table-column>
					<el-table-column label="报读时间" prop="regdate" align="center">
						<template slot-scope="scope">
							{{
								scope.row.regdate | formatDate('YYYY-MM-DD')
							}}
						</template>
					</el-table-column>
					<el-table-column label="应收总额" prop="totalmoney" align="center"></el-table-column>
					<el-table-column label="实收总额" prop="recvmoney" align="center"></el-table-column>
					<el-table-column label="优惠" prop="preferentialmoney" align="center"></el-table-column>
					<el-table-column label="减免" prop="extrasubmoney" align="center"></el-table-column>
					<el-table-column label="退费" prop="feebackmoney" align="center"></el-table-column>
					<el-table-column label="欠费" align="center">
						<template slot-scope="scope">
							{{
								Math.abs(
									scope.row.totalmoney -
									scope.row.recvmoney -
									scope.row.preferentialmoney -
									scope.row.extrasubmoney +
									scope.row.feebackmoney 
								).toFixed(2)
							}}
						</template>
					</el-table-column>
					<el-table-column label="跟进人" prop="followusername" align="center"></el-table-column>
					<el-table-column label="教学点" prop="schoolorgname" align="center"></el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination
				  background
				  @current-change="
				    (val, method) =>
				      handleCurrentChange(val, 'teachfudao_arrearslist')
				  "
				  :current-page="pageinfo.pageindex"
				  :page-size="pageinfo.pagesize"
				  :page-sizes="[10, 20, 30, 40, 50]"
				  layout="total, prev, pager, next, jumper"
				  :total="skillRes.totalcount"
				></el-pagination>
			</el-tab-pane>
    </el-tabs>

    <!-- 代预报名催收 - 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="900px"
      title="收款："
			v-dialogDrag
      :visible.sync="showYubaomingCharge"
      v-if="showYubaomingCharge"
      append-to-body
			:close-on-click-modal='false'
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="收入所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="收入所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据序号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="欠费金额:" prop="totalmoney">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item label="补收金额:" prop="recvmoney">
          <el-input v-model.trim="dynamicValidateForm.recvmoney" clearable/>
        </el-form-item>
        <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type2moneyintypename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type1moneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入类别名称:" prop="typemoneyintypename">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入明细名称:" prop="itemmoneyinitemname">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyinitem_dialogresult, 'itemmoneyModal')"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitYubaomingCharge('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 德立学生学位费催收 - 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="900px"
			v-dialogDrag
      title="收款："
      :visible.sync="showXueweiCharge"
      v-if="showXueweiCharge"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="收入所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="收入所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据序号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="欠费金额:" prop="shouldrecvmoney">
          <el-input v-model="dynamicValidateForm.shouldrecvmoney" disabled />
        </el-form-item>
        <el-form-item label="补交金额:" prop="realrecvmoney">
          <el-input v-model.trim="dynamicValidateForm.realrecvmoney" clearable/>
        </el-form-item>
				<el-form-item label="优惠金额:" prop="discountfee">
				  <el-input v-model.trim="dynamicValidateForm.discountfee" clearable/>
				</el-form-item>
        <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type2moneyintypename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type1moneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入类别名称:" prop="typemoneyintypename">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入明细名称:" prop="itemmoneyinitemname">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyinitem_dialogresult, 'itemmoneyModal')"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitXueweiCharge('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 购买辅导教材费催收 - 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="900px"
			v-dialogDrag
      title="收款："
      :visible.sync="showFudaoCharge"
      v-if="showFudaoCharge"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="收入所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="收入所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据序号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="欠费金额:" prop="totalmoney">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item label="补交金额:" prop="recvmoney">
          <el-input v-model.trim="dynamicValidateForm.recvmoney" clearable/>
        </el-form-item>
        <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type2moneyintypename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type1moneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入类别名称:" prop="typemoneyintypename">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入明细名称:" prop="itemmoneyinitemname">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyinitem_dialogresult, 'itemmoneyModal')"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitFudaoCharge('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 入学注册学费催收 - 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="900px"
			v-dialogDrag
      title="收款："
      :visible.sync="showRuxueCharge"
      v-if="showRuxueCharge"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="收入所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="收入所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据序号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="欠费金额:" prop="money">
          <el-input v-model="dynamicValidateForm.money" disabled />
        </el-form-item>
        <el-form-item label="补交金额:" prop="recvitemmoney">
          <el-input v-model.trim="dynamicValidateForm.recvitemmoney" clearable/>
        </el-form-item>
        <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type2moneyintypename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type1moneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入类别名称:" prop="typemoneyintypename">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入明细名称:" prop="itemmoneyinitemname">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyinitem_dialogresult, 'itemmoneyModal')"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitRuxueCharge('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 报读辅导班学费催收 - 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="900px"
			v-dialogDrag
      title="收款："
      :visible.sync="showBaoduCharge"
      v-if="showBaoduCharge"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="收入所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="收入所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据序号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="欠费金额:" prop="totalmoney">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item label="补交金额:" prop="recvmoney">
          <el-input v-model.trim="dynamicValidateForm.recvmoney" clearable/>
        </el-form-item>
        <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type2moneyintypename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
          <el-input
            v-model="dynamicValidateForm.type1moneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入类别名称:" prop="typemoneyintypename">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入明细名称:" prop="itemmoneyinitemname">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyinitem_dialogresult, 'itemmoneyModal')"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitBaoduCharge('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
      @close="onCloseSelect"
      title="选择账户："
			v-dialogDrag
      :visible.sync="showAccountModal"
      v-if="showAccountModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input 
								v-model.trim="selectSearchForm.accountname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="银行名称:" prop="bankname">
              <el-input 
								v-model.trim="selectSearchForm.bankname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
        <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择收入明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择收入明细"
      :visible.sync="itemmoneyModal"
      v-if="itemmoneyModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-button type="primary" @click="onConfirmItemmoney">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input 
									v-model.trim="selectSearchForm.name" 
									@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
									clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="名称" prop="name" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
    <!-- 选择收入二级类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择收入二级类别"
      :visible.sync="type2moneyModal"
      v-if="type2moneyModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-button type="primary" @click="onConfirmType2moneyModal"
        >确定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="类别:" prop="typename">
              <el-input 
								v-model.trim="selectSearchForm.typename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="一级:" prop="type1name">
              <el-input 
								v-model.trim="selectSearchForm.type1name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="二级:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        />
        <el-table-column
          label="收入类别"
          align="center"
          prop="typename"
        />
        <el-table-column
          label="收入一级项目"
          prop="type1name"
          align="center"
        />
        <el-table-column
          label="收入二级项目"
          prop="name"
          align="center"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
		
		
		<!-- 收款 --技能培训 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="55%"
			v-dialogDrag
			:close-on-click-modal='false'
		  title="收款："
		  :visible.sync="showEnrolModal"
		  v-if="showEnrolModal"
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="35%"
		    class="demo-dynamic form-item-w-50"
		    status-icon
		    :rules="rules"
		  >
		    <el-form-item prop="companyorgname" label="所属分公司">
		      <el-select
		        v-model="dynamicValidateForm.companyorgname"
		        disabled
		        placeholder="请选择"
		        clearable
		        @change="onChangeCompany($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in $store.state.selectData.companyList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item prop="schoolorgname" label="所属教学点">
		      <el-select
		        v-model="dynamicValidateForm.schoolorgname"
		        placeholder="请选择"
		        disabled
		        clearable
		        @change="onChangeSchool($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in $store.state.selectData.schoolList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item prop="fudaofee" label="应收培训费:">
		      <el-input v-model.trim="dynamicValidateForm.fudaofee" disabled/>
		    </el-form-item>
		    <el-form-item prop="recvfudaofee" label="实收培训费:">
		      <el-input v-model.trim="dynamicValidateForm.recvfudaofee" clearable/>
		    </el-form-item>
		    <el-form-item prop="bookfee" label="应收教材费:">
		      <el-input v-model.trim="dynamicValidateForm.bookfee" disabled/>
		    </el-form-item>
		    <el-form-item prop="recvbookfee" label="实收教材费:">
		      <el-input v-model.trim="dynamicValidateForm.recvbookfee" clearable/>
		    </el-form-item>
		    <el-form-item prop="arrears" label="欠费总额:">
		      <el-input v-model.trim="dynamicValidateForm.arrears" disabled />
		    </el-form-item>
		    <el-form-item prop="preferentialmoney" label="优惠金额:">
		      <el-input v-model="dynamicValidateForm.preferentialmoney" disabled />
		    </el-form-item>
		    <el-form-item prop="extrasubmoney" label="额外减免金额:">
		      <el-input v-model.trim="dynamicValidateForm.extrasubmoney" clearable/>
		    </el-form-item>
		    <el-form-item prop="itemmoneyinitemname1" label="培训费收入明细:">
		      <el-input
		        v-model="dynamicValidateForm.itemmoneyinitemname1"
		        disabled
		      />
		      <el-button
		        type="primary"
		        size="small"
		        class="select-btn"
		        @click="
		          onSelectData(moneyinitem_dialogresult, 'showSelectItem1Modal')
		        "
		        >选择</el-button
		      >
		    </el-form-item>
		    <el-form-item prop="shoujucode" label="收据编号:">
		      <el-input v-model="dynamicValidateForm.shoujucode" disabled />
		      <el-button
		        type="primary"
		        size="small"
		        class="select-btn"
		        @click="getshoujuinfo"
		        >重新获取</el-button
		      >
		    </el-form-item>
		    <el-form-item prop="accountname" label="账户名:">
		      <el-input v-model="dynamicValidateForm.accountname" disabled />
		      <el-button
		        type="primary"
		        size="small"
		        class="select-btn"
		        @click="
		          onSelectData(account_dialogresult, 'showAccountModal', {
		            schoolorgid: dynamicValidateForm.schoolorgid,
		          })
		        "
		        >选择</el-button
		      >
		    </el-form-item>
		    <el-form-item label="卡号:">
		      <el-input v-model="dynamicValidateForm.cardno" disabled />
		    </el-form-item>
		    <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
		      <el-input
		        v-model="dynamicValidateForm.type2moneyintypename"
		        disabled
		      />
		      <el-button
		        type="primary"
		        class="select-btn"
		        @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
		        >选择</el-button
		      >
		    </el-form-item>
		    <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
		      <el-input
		        v-model="dynamicValidateForm.type1moneyintypename"
		        disabled
		      />
		    </el-form-item>
		    <el-form-item label="收入类别名称:" prop="typemoneyintypename">
		      <el-input
		        v-model="dynamicValidateForm.typemoneyintypename"
		        disabled
		      />
		    </el-form-item>
		    <el-form-item prop="shoukuanremark" label="开票说明:">
		      <el-input v-model.trim="dynamicValidateForm.shoukuanremark" clearable/>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="onSubmitEnrolForm('dynamicValidateForm')"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>
		
		<!-- 选择收入明细 - 辅导费 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择收入明细："
		  :visible.sync="showSelectItem1Modal"
		  v-if="showSelectItem1Modal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirmMoneyinitem1">确定</el-button>
		
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="名称:" prop="name">
		          <el-input 
									v-model.trim="selectSearchForm.name"
									@keyup.enter.native="onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign({}, selectSearchForm)
		          )"
									clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="
		          onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign({}, selectSearchForm)
		          )
		        "
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleSelect">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55"></el-table-column>
		    <el-table-column
		      label="流水号"
		      prop="id"
		      align="center"
		      width="100"
		    ></el-table-column>
		    <el-table-column
		      label="名称"
		      prop="name"
		      align="center"
		      width="100"
		    ></el-table-column>
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		      (val, data) =>
		        handleSelectChange(val, Object.assign({}, selectSearchForm))
		    "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
  </div>
</template>


<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";

import {
  studentyubaoming_arrearslist,
  studentyubaoming_inarrearscollect,
  studentyubaoming_savearrearscollect,
  studentyubaoming_exportqianfei,
  student_arrearslist,
  student_inarrearscollect,
  student_savearrearscollect,
  student_exportqianfei,
  studentbook_arrearslist,
  studentbook_inarrearscollect,
  studentbook_savearrearscollect,
  studentbook_exportqianfei,
  studentregisterfeedetail_arrearslist,
  studentregisterfeedetail_incollect,
  studentregisterfeedetail_saveincollect,
  studentregisterfeedetail_exportqianfei,
  studentfudao_arrearslist,
	teachfudao_arrearslist,
  studentfudao_inarrearscollect,
  studentfudao_savearrearscollect,
  studentfudao_exportqianfei,
	techfudao_inarrearscollect,
	techfudao_savearrearscollect,
	techfudao_export4,
} from "@/request/api/allChildrenProject";
import {
  _shouju_getshoujuinfo,
  _account_dialogresult,
  _moneyintype2_dialogresult,
  _moneyinitem_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _bankorcash, _available } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "feerecv",
  components: { Export },
  props: {
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    _moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },
    moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    _available: {
      default: () => _available,
    },
  },
  data() {
    return {
			showSelectItem1Modal: false, // 辅导费收入明细框
			showEnrolModal: false, //收费框
      activeName: "yubaoming",
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 页数查询
      multipleSelection: [],
      dynamicValidateForm: {},
      searchForm: {},
      rules: {
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				discountfee: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujucodes: [{ trigger: "blur", message: "必填字段", required: true }],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        accountname: [{ trigger: "blur", message: "必填字段", required: true }],
        type2moneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        type1moneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        typemoneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        arrearage: [{ trigger: "blur", message: "必填字段", required: true }],
        recvmoney: [{ trigger: "blur", message: "必填字段", required: true }],
				recvfudaofee : [{ trigger: "blur", message: "必填字段", required: true }],
				recvbookfee: [{ trigger: "blur", message: "必填字段", required: true }],
				itemmoneyinitemname1: [{ trigger: "blur", message: "必填字段", required: true }],
				shoujucode: [{ trigger: "blur", message: "必填字段", required: true }],
				recvmoney: [{ trigger: "blur", message: "必填字段", required: true }],
				realrecvmoney:[{ trigger: "blur", message: "必填字段", required: true }],
				recvitemmoney: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则

      type2moneyModal: false,
      itemmoneyModal: false,
      showAccountModal: false,
      bankorcash: _bankorcash,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTable: {},
      selectTableUrl: "",
      selectSearchForm: {},

      //代预报名催收数据
      yubaomingRes: {},
      showYubaomingCharge: false, //收款
      //德立学生学位费催收
      xueweiRes: {},
      showXueweiCharge: false, //收款
      //购买辅导教材费催收
      fudaoRes: {},
      showFudaoCharge: false, //收款
      //入学注册学费催收
      ruxueRes: {},
      showRuxueCharge: false, //收款
      //报读辅导班学费催收
      baoduRes: {},
      showBaoduCharge: false, //收款
			//技能培训催收
			skillRes: {},
			key: '',
    };
  },
  created() {
    this.baoming_arrearslist();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //tabs选项卡 click
    onTabsClick(tab, event) {
      this.searchForm = {};
      this.pageinfo.pageindex = 1;
      switch (tab.name) {
        case "yubaoming":
          this.baoming_arrearslist();
          break;
        case "xuewei":
          this.student_arrearslist();
          break;
        case "fudao":
          this.studentbook_arrearslist();
          break;
        case "ruxue":
          this.studentregisterfeedetail_arrearslist();
          break;
        case "baodu":
          this.studentfudao_arrearslist();
          break;
				case "skill":
					this.teachfudao_arrearslist();
					break;
      }
    },
		reset() {
			this.searchForm = {
				qianzainame: null,
				mobile: null,
				followusername: null,
				schoolorgname:null,
				studentname: null,
				fudaotypeval: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.searchYubaoming();
		},
		resetXuewei() {
			this.reset();
			this.student_arrearslist();
		},
		resetFudao() {
			this.reset();
			this.studentbook_arrearslist();
		},
		resetRuxue() {
			this.reset();
			this.studentregisterfeedetail_arrearslist();
		},
		resetBaodu() {
			this.reset();
			this.studentfudao_arrearslist();
		},
		resetTeachfudao() {
			this.reset();
			this.teachfudao_arrearslist();
		},
		selectQuery() {
			this.selectSearchForm = {
				accountname: null,
				bankname: null,
				name: null,
				typename: null,
				typename1: null,
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //代预报名催收 - 搜索
    searchYubaoming() {
      this.pageinfo.pageindex = 1;
      this.baoming_arrearslist();
    },
    //代预报名催收 - 列表 api
    baoming_arrearslist() {
      myRequest({
        url: studentyubaoming_arrearslist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.yubaomingRes = res.data.data;
        }
      });
    },
    //代预报名催收 - 收款 click
    onYubaomingCharge() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.onYubaomingChargeRoute(this.multipleSelection[0].id);
      });
    },
    //代预报名催收 - 收款 - 路由页 api
    onYubaomingChargeRoute(id) {
      myRequest({
        url: studentyubaoming_inarrearscollect,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          const { tstudentyubaoming } = res.data.data;
          this.dynamicValidateForm = {
            id: tstudentyubaoming.id,
            companyorgname: tstudentyubaoming.companyorgname,
            companyorgid: tstudentyubaoming.companyorgid,
            schoolorgname: tstudentyubaoming.schoolorgname,
            schoolorgid: tstudentyubaoming.schoolorgid,
            shoujucode: tstudentyubaoming.shoujucode,
            shoujuid: tstudentyubaoming.shoujuid,
            recvmoney: '',
            qianzaiid: tstudentyubaoming.qianzaiid,
            totalmoney: Math.abs(
              tstudentyubaoming.totalmoney - tstudentyubaoming.recvmoney
            ),
          };
          this.showYubaomingCharge = true;
        }
      });
    },
    //代预报名催收 - 收款 - 提交 click
    onSubmitYubaomingCharge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitYubaomingCharge();
        }
      });
    },
    //代预报名催收 - 收款 - 提交 api
    submitYubaomingCharge() {
      myRequest({
        url: studentyubaoming_savearrearscollect,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.showYubaomingCharge = false;
          this.baoming_arrearslist();
        }
      });
    },
    //代预报名催收 - 导出 click
    onYubaomingExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.open(
            this.$url.Freemarker +
              studentyubaoming_exportqianfei +
              `?${createObjParams(this.searchForm)}`
          );
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //德立学生学位费催收 - 搜索
    searchXuewei() {
      this.pageinfo.pageindex = 1;
      this.student_arrearslist();
    },
    //德立学生学位费催收 - 列表 api
    student_arrearslist() {
      myRequest({
        url: student_arrearslist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.xueweiRes = res.data.data;
        }
      });
    },
    //德立学生学位费催收 - 收款 click
    onXueweiCharge() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.onXueweiChargeRoute(this.multipleSelection[0].id);
      });
    },
    //德立学生学位费催收 - 收款 - 路由页 api
    onXueweiChargeRoute(id) {
      myRequest({
        url: student_inarrearscollect,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          const { vstudent } = res.data.data;
          this.dynamicValidateForm = {
            id: vstudent.id,
            companyorgname: vstudent.companyorgname,
            companyorgid: vstudent.companyorgid,
            schoolorgname: vstudent.schoolorgname,
            schoolorgid: vstudent.schoolorgid,
            shoujucode: vstudent.shoujucode,
            shoujuid: vstudent.shoujuid,
            qianzaiid: vstudent.qianzaiid,
            realrecvmoney:'',
            shouldrecvmoney: Math.abs(
              vstudent.shouldrecvmoney -
                vstudent.realrecvmoney -
								vstudent.discountfee +
                vstudent.feebackmoney
            ),
          };
          this.showXueweiCharge = true;
        }
      });
    },
    //德立学生学位费催收 - 收款 - 提交 click
    onSubmitXueweiCharge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitXueweiCharge();
        }
      });
    },
    //德立学生学位费催收 - 收款 - 提交 api
    submitXueweiCharge() {
      myRequest({
        url: student_savearrearscollect,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.showXueweiCharge = false;
          this.student_arrearslist();
        }
      });
    },
    //德立学生学位费催收 - 导出 click
    onXueweiExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.open(
            this.$url.Freemarker +
              student_exportqianfei +
              `?${createObjParams(this.searchForm)}`
          );
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //购买辅导教材费催收 - 搜索
    searchFudao() {
      this.pageinfo.pageindex = 1;
      this.studentbook_arrearslist();
    },
    //购买辅导教材费催收 - 列表 api
    studentbook_arrearslist() {
      myRequest({
        url: studentbook_arrearslist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.fudaoRes = res.data.data;
        }
      });
    },
    //购买辅导教材费催收 - 收款 click
    onFudaoCharge() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.onFudaoChargeRoute(this.multipleSelection[0].id);
      });
    },
    //购买辅导教材费催收 - 收款 - 路由页 api
    onFudaoChargeRoute(id) {
      myRequest({
        url: studentbook_inarrearscollect,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          const { vstudentbook } = res.data.data;
          this.dynamicValidateForm = {
            id: vstudentbook.id,
            companyorgname: vstudentbook.companyorgname,
            companyorgid: vstudentbook.companyorgid,
            schoolorgname: vstudentbook.schoolorgname,
            schoolorgid: vstudentbook.schoolorgid,
            shoujucode: vstudentbook.shoujucode,
            shoujuid: vstudentbook.shoujuid,
            qianzaiid: vstudentbook.qianzaiid,
            recvmoney: '',
            totalmoney: Math.abs(
              vstudentbook.totalmoney -
                vstudentbook.recvmoney +
                vstudentbook.preferentialmoney +
                vstudentbook.feebackmoney
            ),
          };
          this.showFudaoCharge = true;
        }
      });
    },
    //购买辅导教材费催收 - 收款 - 提交 click
    onSubmitFudaoCharge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFudaoCharge();
        }
      });
    },
    //购买辅导教材费催收 - 收款 - 提交 api
    submitFudaoCharge() {
      myRequest({
        url: studentbook_savearrearscollect,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.showFudaoCharge = false;
          this.studentbook_arrearslist();
        }
      });
    },
    //购买辅导教材费催收 - 导出 click
    onFudaoExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.open(
            this.$url.Freemarker +
              studentbook_exportqianfei +
              `?${createObjParams(this.searchForm)}`
          );
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //入学注册学费催收 - 搜索
    searchRuxue() {
      this.pageinfo.pageindex = 1;
      this.studentregisterfeedetail_arrearslist();
    },
    //入学注册学费催收 - 列表 api
    studentregisterfeedetail_arrearslist() {
      myRequest({
        url: studentregisterfeedetail_arrearslist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.ruxueRes = res.data.data;
        }
      });
    },
    //入学注册学费催收 - 收款 click
    onRuxueCharge() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.onRuxueChargeRoute(this.multipleSelection[0].id);
      });
    },
    //入学注册学费催收 - 收款 - 路由页 api
    onRuxueChargeRoute(id) {
      myRequest({
        url: studentregisterfeedetail_incollect,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          const { vstudentregisterfeedetail } = res.data.data;
          this.dynamicValidateForm = {
            id: vstudentregisterfeedetail.id,
            companyorgname: vstudentregisterfeedetail.companyorgname,
            companyorgid: vstudentregisterfeedetail.companyorgid,
            schoolorgname: vstudentregisterfeedetail.schoolorgname,
            schoolorgid: vstudentregisterfeedetail.schoolorgid,
            shoujucode: vstudentregisterfeedetail.shoujucode,
            shoujuid: vstudentregisterfeedetail.shoujuid,
            qianzaiid: vstudentregisterfeedetail.qianzaiid,
            recvitemmoney: '',
            money: vstudentregisterfeedetail.money,
          };
          this.showRuxueCharge = true;
        }
      });
    },
    //入学注册学费催收 - 收款 - 提交 click
    onSubmitRuxueCharge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitRuxueCharge();
        }
      });
    },
    //入学注册学费催收 - 收款 - 提交 api
    submitRuxueCharge() {
      myRequest({
        url: studentregisterfeedetail_saveincollect,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.showRuxueCharge = false;
          this.studentregisterfeedetail_arrearslist();
        }
      });
    },
    //入学注册学费催收 - 导出 click
    onRuxueExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.open(
            this.$url.Freemarker +
              studentregisterfeedetail_exportqianfei +
              `?${createObjParams(this.searchForm)}`
          );
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //报读辅导班学费催收 - 搜索
    searchBaodu() {
      this.pageinfo.pageindex = 1;
      this.studentfudao_arrearslist();
    },
    //报读辅导班学费催收 - 列表 api
    studentfudao_arrearslist() {
      myRequest({
        url: studentfudao_arrearslist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.baoduRes = res.data.data;
        }
      });
    },
    //报读辅导班学费催收 - 收款 click
    onBaoduCharge() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.onBaoduChargeRoute(this.multipleSelection[0].id);
      });
    },
    //报读辅导班学费催收 - 收款 - 路由页 api
    onBaoduChargeRoute(id) {
      myRequest({
        url: studentfudao_inarrearscollect,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          const { vstudentfudao } = res.data.data;
          this.dynamicValidateForm = {
            id: vstudentfudao.id,
            companyorgname: vstudentfudao.companyorgname,
            companyorgid: vstudentfudao.companyorgid,
            schoolorgname: vstudentfudao.schoolorgname,
            schoolorgid: vstudentfudao.schoolorgid,
            shoujucode: vstudentfudao.shoujucode,
            shoujuid: vstudentfudao.shoujuid,
            qianzaiid: vstudentfudao.qianzaiid,
            totalmoney: vstudentfudao.totalmoney,
            recvmoney: '',
          };
          this.showBaoduCharge = true;
        }
      });
    },
    //报读辅导班学费催收 - 收款 - 提交 click
    onSubmitBaoduCharge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitBaoduCharge();
        }
      });
    },
    //报读辅导班学费催收 - 收款 - 提交 api
    submitBaoduCharge() {
      myRequest({
        url: studentfudao_savearrearscollect,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.showBaoduCharge = false;
          this.studentfudao_arrearslist();
        }
      });
    },
    //报读辅导班学费催收 - 导出 click
    onBaoduExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.open(
            this.$url.Freemarker +
              studentfudao_exportqianfei +
              `?${createObjParams(this.searchForm)}`
          );
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
		//搜索 --技能培训催收
		searchTeachfudao() {
			this.pageinfo.pageindex = 1;
			this.teachfudao_arrearslist();
		},
		//技能培训催收
		teachfudao_arrearslist() {
			myRequest({
				url: teachfudao_arrearslist,
				data: Object.assign(this.searchForm, this.pageinfo),
			}).then((res) => {
				if( res.data.code === "200") {
						this.skillRes = res.data.data;
				}
			})
		},
		//收款--技能培训催收
		onTeachCharge() {
			selectCheck(this.multipleSelection, "收款", false, () => {
				this.collect(this.multipleSelection[0].id);
			})
		},
		//收费 api
		collect(id) {
		  myRequest({
		    url: techfudao_inarrearscollect,
		    data: {
		      id: id,
		    },
		  }).then((res) => {
				console.log(res,'这个时候收费------')
		    if (res.data.code === "200") {
		      this.dynamicValidateForm = Object.assign(
		        res.data.data.tmoneyin,
		        res.data.data.techfudao,
						res.data.data.arrears,
		      );
		      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
					this.dynamicValidateForm.arrears= res.data.data.arrears;
		      this.dynamicValidateForm.itemmoneyinitemname1 = "培训费";
		      this.dynamicValidateForm.recvbookfee = '',
					this.dynamicValidateForm.recvfudaofee = '',
		      delete this.dynamicValidateForm.regdate;
		      delete this.dynamicValidateForm.inserttime;
		      delete this.dynamicValidateForm.finishdate;
		      this.getshoujuinfo();
		      this.showEnrolModal = true;
		    }
		  });
		},
		//提交收款 click
		onSubmitEnrolForm(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      myRequest(
		        {
		          url: techfudao_savearrearscollect,
		          data: this.dynamicValidateForm,
		        },
		        {
		          isRefTableList: true,
		          that: this,
		          modal: "showEnrolModal",
		        }).then((res) => {
							if(res.data.code === "200") {
								this.showEnrolModal = false;
								this.teachfudao_arrearslist();
							}
						})
		    }
		  });
		},
		onTeachExport() {
			confirmCallBack({
				title: "提示",
				content: "根据搜索条件进行导出。",
				success: () => {
					window.open(
						this.$url.Freemarker +
							techfudao_export4 +
								`?${createObjParams(this.searchForm)}`
					)
				},
				fail: () => {
					messageTip({
						message: "取消操作",
						type: "info",
					});
				},
			});
		},
		showSelect() {
			this.selectSearchForm = {
				name: null,
				type1name: null,
				typename: null
			}
		},
		handleSelect() {
			this.showSelect();
			this.onSelectData(this.selectTableUrl, null, null);
		},
		// 确定 - 辅导费收入明细 click
		onConfirmMoneyinitem1() {
		  selectCheck(this.multipleSelection, "辅导费收入明细", false, () => {
		    this.$set(
		      this.dynamicValidateForm,
		      "itemmoneyinitemname1",
		      this.multipleSelection[0].name
		    );
		    this.showSelectItem1Modal = false;
				this.showSelect();
				this.multipleSelection = [];
		  });
		},
		
    //确认账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "确认账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showAccountModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认收入明细 click
    onConfirmItemmoney() {
			selectCheck(this.multipleSelection, "确认明细", false, ()=> {
				this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
				this.$set(
				  this.dynamicValidateForm,
				  "itemmoneyinitemname",
				  this.multipleSelection[0].name
				);
				this.itemmoneyModal = false;
				this.selectQuery();
				this.multipleSelection = [];
			});
      
    },
    //选择收入二级类型确定 click
    onConfirmType2moneyModal() {
			selectCheck(this.multipleSelection, "确定收入二级类型", false, () => {
				this.$set(
				  this.dynamicValidateForm,
				  "type2moneyintypename",
				  this.multipleSelection[0].name
				);
				this.$set(
				  this.dynamicValidateForm,
				  "type1moneyintypename",
				  this.multipleSelection[0].type1name
				);
				this.$set(
				  this.dynamicValidateForm,
				  "typemoneyintypename",
				  this.multipleSelection[0].typename
				);
				this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
				this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
				this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
				this.type2moneyModal = false;
				this.selectQuery();
				this.multipleSelection = [];
			});
     
    },

    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选项表格 api（）
    onSelectData(url, modal, data, row, prop, page) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //获取收据号 api
    getshoujuinfo() {
      myRequest({
        url: _shouju_getshoujuinfo,
        data: {
          schoolorgid: this.dynamicValidateForm.schoolorgid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
          this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //切换当前页
    handleCurrentChange(val, method) {
      this.pageinfo.pageindex = val;
      this[method]();
    },
  },
  
};
</script>

<style  lang="scss">
.feerecv-w {
  padding: 10px;
  .search-form{
    padding: 0 0 10px 0;
  }
}
</style>